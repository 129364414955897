/* @import url("https://use.typekit.net/wuc7jut.css"); */

html,
body,
#root {
  margin: 0;
  width: 100%;
  background-color: #ece9e0;
}

body {
  font-family: system-ui;
  margin: 0;
}



*,
*:after,
*:before {
  box-sizing: border-box;
}
